export default () => {
    let toggles = document.querySelectorAll('[data-categorytoggle]')

    toggles.forEach((toggle) => {
        toggle.addEventListener('click', (event) => {
            event.preventDefault()

            toggles.forEach((toggle) => {
                toggle.classList.remove('selected')
            })
            toggle.classList.add('selected')

            let toggleables = document.querySelectorAll(`[data-category]`)
            toggleables.forEach((toggleable) => {
                if (toggleable.dataset.category === toggle.dataset.categorytoggle) {
                    toggleable.classList.remove('hide')
                } else {
                    toggleable.classList.add('hide')
                }
                if (!toggle.dataset.categorytoggle){
                    toggleable.classList.remove('hide')
                }
            })

        })
    })
}
