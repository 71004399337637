import { h, Component } from 'preact'
import ErrorMessage from '../../components/ErrorMessage'
import Loader from '../../components/Loader'
import Post from './Post'
import Pagination from './Pagination'

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

class Posts extends Component {
    constructor (props) {
        super(props)
    }

    render () {
        if (this.props.error) {
            return <ErrorMessage type="posts-error"/>
        }

        if (!this.props.loading && this.props.posts.length === 0) {
            return <ErrorMessage type="no-results"/>
        }

        return (
            <div className="c-blog__posts">
                <div className="c-blog__post-row">
                    {this.props.posts.map(post => {
                        return <Post post={post}
                                     key={post.id}
                                     language={this.props.language} />
                    })}
                </div>
            </div>
        )
    }
}

export default Posts
