import {h, render} from 'preact'
import App from './containers/App'

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

const container = document.getElementById('js-blog-overview')

if (container) {
    const language = (container.dataset.language ? container.dataset.language : 'nl')
    const blogsettings = (container.dataset.blogsettings ? container.dataset.blogsettings : null)
    render(<App language={language} blogsettings={blogsettings} />, container)
}
