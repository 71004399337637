class API {

    static all (url, parameters = {}) {
        url = new URL(url)

        Object.keys(parameters).forEach((key) => {
            url.searchParams.set(key, parameters[key])
        })

        return fetch(url, {
            credentials: 'same-origin',
        }).then(this.handleStatus)
    }

    static handleStatus (response) {
        if (!response.ok) {
            // Fetch doesn't reject on HTTP error status
            throw Error(`Request rejected with status ${response.status}`)
        }

        return response
    }

}

export default API
