import { h, Component } from 'preact'

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

class Pagination extends Component {
    constructor (props) {
        super(props)

        this.handlePage = this.handlePage.bind(this)
    }

    handlePage (e) {
        this.props.onSelecPage(e.target.value)
    }

    render () {
        if (this.props.total <= 1) {
            return
        }

        let prev = this.props.page - 1;
        let next = this.props.page + 1;

        return (
            <div className="c-pagination u-flex c-blog__pagination">
                {prev ? (
                    <button value={prev} onClick={this.handlePage}
                       className="c-pagination__page c-pagination__page--prev u-flex u-flex-v-center">
                        <span className="c-pagination__text"><i className="fas fa-arrow-left"></i></span>
                    </button>
                ) : ''}
                {[...Array(this.props.total).keys()].map((page) => {
                    page++
                    return (
                        <button value={page} onClick={this.handlePage}
                           className={`c-pagination__page ${this.props.page === page ? 'active' : ''}`}>{page}</button>
                    )
                })}
                {next && next <= this.props.total ? (
                    <button value={next} onClick={this.handlePage}
                       className="c-pagination__page c-pagination__page--next u-flex u-flex-v-center">
                        <span className="c-pagination__text"><i className="fas fa-arrow-right"></i></span>
                    </button>
                ) : ''}
            </div>
        )
    }
}

export default Pagination
