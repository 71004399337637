import { h, Component } from 'preact'
import API from '../../api/API'
import ErrorMessage from '../../components/ErrorMessage'
import Loader from '../../components/Loader'

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

class CategoryFilter extends Component {
    constructor (props) {
        super(props)

        this.state = {
            categories: [],
            error: false,
            isLoading: true,
        }

        this.handleCategory = this.handleCategory.bind(this)
    }

    componentDidMount () {
        this.fetchCategories()
    }

    fetchCategories() {
        this.setState({ isLoading: true })

        API.all(
            window.location.origin + '/wp-json/barth/categories',
            {}
        ).then(response => {
            return response.json()
        }).then(data => {
            this.setState({
                categories: [...this.state.categories, ...data],
                isLoading: false,
            })
        }).catch(error => {
            this.setState({
                error: true,
                isLoading: false,
            })
        })
    }

    handleCategory (e) {
        this.props.onSelectCategory(e.target.value)
    }

    render () {
        let { error, isLoading } = this.state

        if (error) {
            return <ErrorMessage type="filter-error"/>
        }

        if (isLoading) {
            return <Loader/>
        }

        let filter_text = 'Filter door alle blogs'
        let all_text = 'Alle blogs'

        if (this.props.language == 'en') {
            filter_text = 'Filter through all blogs'
            all_text = 'All blogs'
        }

        return (
            <div className="c-blog-filter">
                <div className="o-container">
                    <div className="filter-label">{filter_text}</div>
                    <div className="filters">
                        <button className={`c-button c-button--gray ${this.props.currentCategory ? '' : 'active'}`} value="" onClick={this.handleCategory}>
                            {all_text}
                        </button>
                        {this.state.categories.map(category => {
                            return (
                                <button className={`c-button c-button--gray ${this.props.currentCategory === category.slug ? 'active' : ''}`} value={category.slug} key={category.slug} onClick={this.handleCategory}>
                                    {category.name}
                                </button>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default CategoryFilter
