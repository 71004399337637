import { h, Component } from 'preact'
import Moment from 'moment'

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

class Post extends Component {
    constructor (props) {
        super(props)
    }

    render () {
        return (
            <a href={this.props.post.link} className={`c-post u-flex c-blog__post c-blogs__col`}>
                <div className="c-post__image-container">
                    <div className="c-post__image u-image" dangerouslySetInnerHTML={{ __html: this.props.post.image }}></div>
                </div>
                <div className="c-post__content">
                    <div className="c-post__body">
                        <h2 className="c-post__title">{this.props.post.title}</h2>
                        <div className="c-post__excerpt">
                            <p>{this.props.post.meta.excerpt}</p>
                        </div>
                    </div>
                </div>
                <div className="c-post__footer">
                    <div className="c-post-meta c-post__footer-meta">
                        <div className="c-post-meta__date">
                            {this.props.post.date}
                        </div>
                    </div>
                    <div className="c-post__terms">{this.props.post.categories.join(', ')}</div>
                    <button className="c-button ">
                        Lees meer <i className="fas fa-arrow-right"></i>
                    </button>
                </div>
            </a>
        )
    }
}

export default Post
