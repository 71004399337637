import { h, Component } from 'preact'
import API from '../../api/API'
import CategoryFilter from './CategoryFilter'
import Posts from '../components/Posts'
import Post from '../components/Post'
import Loader from '../../components/Loader'
import Pagination from '../components/Pagination'

// Tell Babel to transform JSX into h() calls:
/** @jsx h */

class App extends Component {
    constructor (props) {
        super(props)

        this.state = {
            posts: [],
            page: 1,
            currentCategory: '',
            total: null,
            isLoading: true,
            error: false,
        }

        this.handleLoadMore = this.handleLoadMore.bind(this)
        this.handleCategoryChoice = this.handleCategoryChoice.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
    }

    componentDidMount () {
        this.fetchPosts()
    }

    componentDidUpdate () {
        window.dispatchEvent(new Event('resize'))
    }

    fetchPosts () {
        this.setState({ isLoading: true })

        API.all(
            window.location.origin + '/wp-json/barth/posts',
            {
                page: this.state.page,
                category: this.state.currentCategory,
            },
        ).then(response => {
            this.setState({ total: response.headers.get('x-wp-total') })
            return response.json()
        }).then(data => {
            window.setTimeout(() => {
                this.setState({
                    posts: [...data.posts],
                    page: data.page,
                    total: data.total,
                    isLoading: false,
                })
            }, 500)
        }).catch(error => {
            this.setState({
                error: true,
                isLoading: false,
            })
        })
    }

    handleLoadMore () {
        this.setState((prevState) => ({ page: prevState.page + 1 }), this.fetchPosts)
    }

    handleCategoryChoice (value) {
        this.setState(() => {
            return {
                currentCategory: value,
                posts: [],
                page: 1,
            }
        }, this.fetchPosts)
    }

    handlePageChange (value) {
        this.setState(() => {
            return {
                page: value,
            }
        }, this.fetchPosts)
    }

    render () {
        return (
            <div>
                <CategoryFilter
                    currentCategory={this.state.currentCategory}
                    onSelectCategory={this.handleCategoryChoice}
                    language={this.props.language}
                    categories={this.props.categories}
                />

                <div className={`c-blog ${this.props.blogsettings}`}>
                    {this.state.isLoading ? (<Loader />) : (
                        <div className="o-container">
                            <Posts
                                posts={this.state.posts}
                                loading={this.state.isLoading}
                                error={this.state.error}
                                language={this.props.language}
                            />
                            <Pagination page={this.state.page} total={this.state.total} onSelecPage={this.handlePageChange} />
                        </div>
                    )}
                </div>
            </div>
        )
    }

}

export default App
